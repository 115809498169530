import React, { useRef } from "react";
import { sendForm } from "emailjs-com";
import {
  Form,
  Input,
  SendButton,
  TextArea,
  Toaster,
} from "./ContactMeForm.styles";

const ContactMeForm = () => {
  const form = useRef();
  const confirmMsg = useRef();
  const errorMsg = useRef();

  const handleMailSend = (e) => {
    e.preventDefault();

    sendForm(
      process.env.REACT_APP_EMAILJS_SERVICE_ID,
      process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
      form.current,
      process.env.REACT_APP_EMAILJS_USER_ID
    ).then(
      () => {
        e.target.reset();
        confirmMsg.current.classList.add("animate");
        errorMsg.current.classList.remove("animate");
        setTimeout(() => {
          confirmMsg.current.classList.remove("animate");
        }, 4000);
      },
      () => {
        errorMsg.current.className += " animate";
      }
    );
  };

  return (
    <Form ref={form} onSubmit={handleMailSend}>
      <Input type="text" name="name" placeholder="שם*" required />
      <Input type="email" name="mail" placeholder="מייל*" required />
      <Input type="tel" name="phoneNumber" placeholder="טלפון*" required />
      <TextArea
        wrap="hard"
        rows="5"
        name="content"
        placeholder="תוכן ההודעה*"
        required
      />
      <SendButton value="שלח הודעה" />
      <Toaster ref={confirmMsg}>ההודעה נשלחה בהצלחה!</Toaster>
      <Toaster ref={errorMsg}>משהו השתבש בשליחה.. נסה שנית</Toaster>
    </Form>
  );
};

export default ContactMeForm;
