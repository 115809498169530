import React from "react";
import {
  Card,
  CardBack,
  CardFront,
  CardHeading,
  CardPicture,
  CardPoints,
  CardSide,
  PointText,
} from "./SpecialityCard.styles";

const SpecialityCard = ({ speciality }) => {
  return (
    <Card>
      <CardSide>
        <CardFront>
          <CardPicture background={speciality.image} />
          <CardHeading large>{speciality.title}</CardHeading>
        </CardFront>
        <CardBack background={speciality.image}>
          <CardHeading>{speciality.title}</CardHeading>
          <CardPoints>
            {speciality.points.map((point, index) => (
              <PointText key={`speciality${speciality.title}-point${index}`}>
                &#8226; {point}
              </PointText>
            ))}
          </CardPoints>
        </CardBack>
      </CardSide>
    </Card>
  );
};

export default SpecialityCard;
