import React from "react";
import {
  Layout,
  RowLayout,
  Location,
  Text,
  People,
} from "./LocationNContact.styles";

const LocationNContact = () => (
  <Layout>
    <RowLayout>
      <Location />
      <Text> קליניקה ברעננה</Text>
    </RowLayout>
    <RowLayout>
      <People />
      <Text>טיפול פרטני פרונטלי או באונליין באמצעות זום</Text>
    </RowLayout>
  </Layout>
);

export default LocationNContact;
