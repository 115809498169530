import React from "react";
import { AppBarLayout, MainTitle, SecondaryTitle } from "./AppBar.styles";

const AppBar = () => {
  return (
    <AppBarLayout>
      <MainTitle>איילת אונגר</MainTitle>
      <SecondaryTitle>פסיכותרפיה &bull; הדרכה &bull; פיתוח מקצועי</SecondaryTitle>
    </AppBarLayout>
  );
};

export default AppBar;
