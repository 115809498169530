import React from "react";
import styled, { keyframes } from "styled-components";
import { ReactComponent as ArrowIcon } from "../../assets/img/chevron-up.svg";

const animation = keyframes`
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
`;

const StyledButton = styled.button`
  position: fixed;
  bottom: 5vh;
  right: 5vh;
  border-radius: 50%;
  background-color: rgba(256, 256, 256, 0.7);
  width: 50px;
  height: 50px;
  border: none;
  box-shadow: ${(props) => props.theme.shadow};
  z-index: 9999;
  cursor: pointer;
  transition: transform 0.2s ease;
  animation: ${animation} 2s forwards;

  &:hover {
    transform: scale(1.1) translateY(-2px);
  }
`;

const TopButton = ({ onClick }) => (
  <StyledButton onClick={onClick}>
    <ArrowIcon />
  </StyledButton>
);

export default TopButton;
