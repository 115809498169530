import React from "react";
import {
  MyAgendaQuote,
  MyAgendaQuoteOwner,
  MyAgendaQuoteText,
  MyAgendaSectionLayout,
  MyAgendaText,
  MyAgendaTitle,
} from "./MyAgendaSection.styles";

const MyAgendaSection = () => {
  return (
    <MyAgendaSectionLayout>
      <MyAgendaTitle>האני מאמין שלי</MyAgendaTitle>
      <MyAgendaQuote>
        <MyAgendaQuoteText>
          כי עוד אאמין גם באדם, גם ברוחו, רוח עז
        </MyAgendaQuoteText>
        <MyAgendaQuoteOwner> (שאול טשרניחובסקי)</MyAgendaQuoteOwner>
      </MyAgendaQuote>
      <MyAgendaText>
        בעבודתי כמטפלת קלינית וכאשת שיקום הדגש המשמעותי הינו האמונה באדם
        ובכוחותיו לצלוח ולהתמודד באופן מיטבי עם המשברים והקשיים המזדמנים לאורך
        מעגל החיים. עבודתי מתבססת על גישות מוכוונות אדם, הומניסטיות ועבודה
        מערכתית הוליסטית ומשלבת בתוכה טכניקות של הנעה לשינוי, מיינדפולנס, וכלים
        שונים מגישות טיפוליות שונות שאספתי איתי בדרך ומותאמות לצרכיו של המטופל
      </MyAgendaText>
    </MyAgendaSectionLayout>
  );
};

export default MyAgendaSection;
