import styled from "styled-components";
import { SecondaryTitle } from "../generals/Typography.styles";

export const MySpecialitiesSectionLayout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  /* transform: rotate(-180deg); */
  padding: 80px 20px;
  /* padding-top: 80px; */
  width: 100%;
  height: 100%;

  @media only screen and (max-width: ${(props) => props.theme.breakpoints.s}) {
    height: auto;
    padding-top: 20px;
  }
`;

export const MySpectialitiesTitle = styled(SecondaryTitle)`
  margin-bottom: 15px;
`;

export const MySpecialitiesCardsContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-around;

  @media only screen and (max-width: ${(props) => props.theme.breakpoints.ms}) {
    flex-direction: column;
    /* flex-wrap: wrap; */
    align-items: center;
    align-content: space-between;
  }
`;
