const theme = {
  palette: {
    mainColor: "#CCD5AE",
    mainColorLight: "#E9EDC9",
    mainColorDark: "#8FA253",
    secondaryColorDark: "#FAEDCD",
    secondaryColor: "#fef9ed",
    secondaryColorLight: "#fffbf3",
    tertiaryColor: "#D4A373",
    tertiaryColorDark: "#a38160",
    white: "#fff",
    black: "#000",
  },
  fontFamily: `'Rubik', sans-serif`,
  shadow: "4px 0 8px #565555ab",
  breakpoints: {
    s: "600px",
    ms: "750px",
    m: "880px",
    l: "1200px",
    xl: "1400px",
  },
};

export default theme;
