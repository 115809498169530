import styled from "styled-components";

export const AppBarLayout = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;

  padding: 10px;
  height: 100%;
`;

export const MainTitle = styled.h1`
  color: ${(props) => props.theme.palette.tertiaryColorDark};
  font-size: 30px;
  font-weight: 400;
  letter-spacing: 15px;
  margin-bottom: 7px;

  @media only screen and (max-width: ${(props) => props.theme.breakpoints.s}) {
    font-size: 20px;
    letter-spacing: 13px;
  }
`;

export const SecondaryTitle = styled.h2`
  color: ${(props) => props.theme.palette.tertiaryColorDark};
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 12px;
  @media only screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    letter-spacing: 0;
  }

  @media only screen and (max-width: ${(props) => props.theme.breakpoints.s}) {
    font-size: 12px;
  }
`;
