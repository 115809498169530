import styled from "styled-components";

export const FixedTopOfPage = styled.div`
  position: fixed;
  top: 2px;
  width: 10px;
  height: 10px;
`;

export const HomepageContainer = styled.div`
  margin-top: 12vh;
  display: grid;
  overflow-x: hidden;
  grid-template-rows: min-content 80vh 88vh repeat(3, min-content);
  grid-template-columns: // add 1 fr before and after the 8 cols to center it, besides the sidebar

    [full-start] minmax(6rem, 1fr)
    [center-start] repeat(8, [col-start] minmax(min-content, 14rem) [col-end])
    [center-end] minmax(6rem, 1fr) [full-end];

  font-family: ${(props) => props.theme.fontFamily};

  @media only screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    grid-template-rows: repeat(2, min-content) 88vh repeat(3, min-content);
    grid-template-columns: // add 1 fr before and after the 8 cols to center it, besides the sidebar

      [full-start] minmax(6rem, 1fr)
      [center-start] repeat(8, [col-start] minmax(min-content, 14rem) [col-end])
      [center-end] minmax(6rem, 1fr) [full-end];
  }

  @media only screen and (max-width: ${(props) => props.theme.breakpoints.ms}) {
    grid-template-rows: min-content max-content max-content repeat(
        3,
        min-content
      );
  }
`;

export const AppBarContainer = styled.div`
  background-color: ${(props) => props.theme.palette.secondaryColor};
  box-shadow: ${(props) => props.theme.shadow};
  z-index: 1000;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 12vh;
`;

export const AboutSectionContainer = styled.section`
  background: linear-gradient(
    161deg,
    ${(props) => props.theme.palette.mainColorDark} 0%,
    ${(props) => props.theme.palette.mainColor} 50%
  );
  grid-column: full-start / full-end;
  grid-row: 1 / 2;

  display: block;
  padding: 100px 0;
  padding-top: 50px;
  margin: 0 auto;
  overflow: hidden !important;

  &::after {
    content: "";
    display: table;
    clear: both;
  }

  @media only screen and (max-width: ${(props) => props.theme.breakpoints.s}) {
    padding: 30px 0;
  }
`;

export const MyAgendaSectionContainer = styled.section`
  background-color: ${(props) => props.theme.palette.secondaryColorLight};
  grid-column: full-start / full-end;
  grid-row: 2 / 3;
  z-index: 10;
`;

export const SpecialitiesSectionContainer = styled.section`
  background-image: linear-gradient(
    180deg,
    ${(props) => props.theme.palette.secondaryColorDark},
    ${(props) => props.theme.palette.secondaryColorLight}
  );
  grid-column: full-start / full-end;
  grid-row: 3 / 4;
`;

export const LocationInfoSectionContainer = styled.section`
  background-color: ${(props) => props.theme.palette.tertiaryColorDark};
  grid-column: full-start / full-end;
  grid-row: 4 / 5;
`;

export const ContactMeSectionContainer = styled.section`
  background-color: ${(props) => props.theme.palette.secondaryColor};
  grid-column: full-start / full-end;
  grid-row: 5 / 6;
`;

export const FooterContainer = styled.footer`
  background-color: ${(props) => props.theme.palette.secondaryColor};
  grid-column: full-start / full-end;
  grid-row: 7 / 8;
`;
