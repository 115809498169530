import styled from "styled-components";
import { Paragraph, SecondaryTitle } from "../generals/Typography.styles";

export const MyAgendaSectionLayout = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  position: relative;
  padding: 20px;
  width: 100%;
  height: 100%;

  &::before {
    content: '"';
    position: absolute;
    top: -30px;
    right: 125px;
    font-size: 35vw;
    font-style: italic;
    font-family: "Secular One", sans-serif;
    color: ${(props) => props.theme.palette.tertiaryColor}60;
    line-height: 1;
    z-index: 1;

    @media only screen and (max-width: ${(props) =>
        props.theme.breakpoints.s}) {
      top: 20px;
      right: 30px;
      font-size: 45vw;
    }

    @media only screen and (max-width: ${(props) =>
        props.theme.breakpoints.m}) {
      top: 30px;
      right: 50px;
      font-size: 40vw;
    }
  }

  @media only screen and (max-width: ${(props) => props.theme.breakpoints.s}) {
    padding: 10px;
    height: auto;
    margin-bottom: 30px;
  }
`;

export const MyAgendaTitle = styled(SecondaryTitle)`
  margin-top: 7vh;
`;

export const MyAgendaQuote = styled.div`
  /* width: 100%; */
  display: flex;
  justify-content: flex-start;
  margin: 7vh 0 7vh 0;

  @media only screen and (max-width: ${(props) => props.theme.breakpoints.s}) {
    flex-direction: column;
    margin: 4vh 0 4vh 0;
  }
`;

export const MyAgendaQuoteText = styled(Paragraph)`
  font-weight: 500;
  font-style: italic;
`;

export const MyAgendaQuoteOwner = styled(Paragraph)`
  margin-right: 5vh;

  font-size: 12px;
  font-weight: 500;

  @media only screen and (max-width: ${(props) => props.theme.breakpoints.s}) {
    margin-right: 0;
  }
`;

export const MyAgendaText = styled(Paragraph)`
  width: 60%;
  @media only screen and (max-width: ${(props) => props.theme.breakpoints.s}) {
    width: 80%;
  }
`;
