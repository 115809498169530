import styled from "styled-components";

export const Card = styled.div`
  width: 20vw;
  position: relative;

  @media only screen and (max-width: ${(props) => props.theme.breakpoints.l}) {
    width: 28vw;
  }

  @media only screen and (max-width: ${(props) => props.theme.breakpoints.ms}) {
    width: 50vw;
  }

  @media only screen and (max-width: ${(props) => props.theme.breakpoints.s}) {
    width: 70vw;
  }
`;

export const CardSide = styled.div`
  position: absolute;
  top: 5vh;
  left: -5px;
  width: 98%;
  height: 60vh;
  overflow: hidden;
  backface-visibility: hidden;
  transition: all 1.5s ease;
  perspective: 700px;

  @media only screen and (max-width: ${(props) => props.theme.breakpoints.ms}) {
    position: relative;
    top: 0;
    left: 0;
    margin-bottom: 20px;
    height: auto;
  }
`;

export const CardFront = styled(CardSide)`
  background-color: ${(props) => props.theme.palette.secondaryColor};
  box-shadow: ${(props) => props.theme.shadow};
  position: relative;

  ${Card}:hover & {
    transform: rotateY(-180deg);
    @media only screen and (max-width: ${(props) =>
        props.theme.breakpoints.ms}) {
      transform: rotateY(0);
    }
  }

  @media only screen and (max-width: ${(props) => props.theme.breakpoints.ms}) {
    z-index: 999;
    box-shadow: none;
  }
`;

export const CardBack = styled(CardSide)`
  background-color: ${(props) => props.theme.palette.secondaryColor};
  transform: rotateY(180deg);
  box-shadow: ${(props) => props.theme.shadow};
  padding: 15px;

  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: flex-start;
  align-items: center;

  ${Card}:hover & {
    transform: rotateY(0);
    @media only screen and (max-width: ${(props) => props.theme.breakpoints.ms}) {
      transform: rotateY(0);
    }
  }

  @media only screen and (max-width: ${(props) => props.theme.breakpoints.ms}) {
    transform: rotateY(0);
    box-shadow: none;

    &::before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      width: 100%;
      height: 100%;
      opacity: 0.2;
      background-image: linear-gradient(to right bottom, #fff3a833, #a7ca3485),
        url(${(props) => props.background});
      background-position: center;
      background-size: cover;
      background-blend-mode: multiply;
    }
  }
`;

export const CardPicture = styled.div`
  background-image: linear-gradient(to right bottom, #fff3a833, #a7ca3485),
    url(${(props) => props.background});
  background-position: center;
  background-size: cover;
  background-blend-mode: multiply;
  clip-path: polygon(0 0, 100% 10%, 100% 100%, 0 100%);
  height: 40vh;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
`;

export const CardHeading = styled.h4`
  color: ${(props) => props.theme.palette.tertiaryColorDark};

  @media only screen and (max-width: ${(props) => props.theme.breakpoints.ms}) {
    color: ${(props) => props.theme.palette.mainColorDark};
    font-size: 30px;
  }

  @media only screen and (max-width: ${(props) => props.theme.breakpoints.s}) {
    font-size: 25px;
  }

  ${(props) =>
    props.large
      ? `
    position: absolute;
    top: 11vh;
    right: 30%;
  font-size: 36px;
  font-weight: 600;`
      : `
  font-size: 36px;
  font-weight: 500;
  margin-bottom: 20px`}
`;

export const CardPoints = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`;

export const PointText = styled.p`
  font-size: 15px;
  letter-spacing: 1px;
  font-weight: 500;
  color: #8fa253;
  margin-bottom: 15px;

  @media only screen and (min-width: ${(props) => props.theme.breakpoints.l}) {
    line-height: 1.5;
    font-size: 13px;
  }

  @media only screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    font-size: 14px;
    font-weight: 400;
  }

  @media only screen and (max-width: ${(props) => props.theme.breakpoints.ms}) {
    background-color: rgba(256, 256, 256, 0.8);
    color: ${(props) => props.theme.palette.tertiaryColorDark};
  }
`;
