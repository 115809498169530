import styled from "styled-components";
import { ReactComponent as EmailIcon } from "../../assets/img/at-sign.svg";
import { ReactComponent as PhoneIcon } from "../../assets/img/phone-call.svg";
import { Paragraph } from "../generals/Typography.styles";

export const Layout = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  padding: 20px;
`;

export const DetailsContainer = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media only screen and (max-width: ${(props) => props.theme.breakpoints.s}) {
    flex-direction: column;
  }
`;

export const Row = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const Text = styled(Paragraph)`
  text-shadow: none;
`;

export const Email = styled(EmailIcon)`
  width: 20px;
  height: 20px;
  margin: 0 25px 0 10px;
  fill: ${(props) => props.theme.palette.tertiaryColorDark};
`;

export const Phone = styled(PhoneIcon)`
  width: 20px;
  height: 20px;
  margin-left: 15px;
  fill: ${(props) => props.theme.palette.tertiaryColorDark};
`;

export const Link = styled.a`
  text-decoration: none;
`;
