import styled from "styled-components";
import { ReactComponent as LocationIcon } from "../../assets/img/map-pin.svg";
import { ReactComponent as PeopleIcon } from "../../assets/img/users1.svg";
import { Paragraph } from "../generals/Typography.styles";

export const Layout = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
`;

export const RowLayout = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
`;

export const Location = styled(LocationIcon)`
  width: 20px;
  height: 20px;
  margin-left: 15px;
  fill: ${(props) => props.theme.palette.secondaryColorDark};
`;

export const People = styled(PeopleIcon)`
  width: 20px;
  height: 20px;
  margin-left: 15px;
  fill: ${(props) => props.theme.palette.secondaryColorDark};
`;

export const Text = styled(Paragraph)`
  color: ${(props) => props.theme.palette.secondaryColorDark};
  @media only screen and (max-width: ${(props) => props.theme.breakpoints.s}) {
    font-size: 14px;
  }
`;
