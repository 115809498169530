import React from "react";
import {
  AboutPhoto,
  AboutPhotoContainer,
  AboutSectionLayout,
  AboutTextContainer,
} from "./AboutSection.styles";
import MyPhoto from "../../assets/img/IMG_20141225_215615.jpg";

const AboutSection = () => {
  return (
    <AboutSectionLayout>
      <AboutPhotoContainer>
        <AboutPhoto src={MyPhoto} alt="my-photo" />
      </AboutPhotoContainer>
      <AboutTextContainer>
        בעלת תואר מוסמך בעבודה סוציאלית קלינית מטעם אוניברסיטת בר אילן, מתמחה
        בפסיכותרפיה אינטגרטיבית בתכנית עוגן במכללה האקדמית תל אביב יפו. עוסקת
        מזה שנים רבות בתחום בריאות הנפש. מטפלת פרטנית במבוגרים במכון תלמ רעננה
        ובקליניקה פרטית ברעננה. מדריכה ומלמדת מודלים שיקומיים בבריאות הנפש
        בקליניקה פרטית ובמכללה האקדמית אונו במסגרת בית הספר לשילוב שיקום והחלמה
        בבריאות הנפש
      </AboutTextContainer>
    </AboutSectionLayout>
  );
};

export default AboutSection;
