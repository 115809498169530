import React from "react";
import {
  MySpecialitiesSectionLayout,
  MySpectialitiesTitle,
  MySpecialitiesCardsContainer,
} from "./MySpectialitiesSection.styles";
import SpecialityCard from "./specialityCard/SpecialityCard";
import { specilities } from "./MySpecialities";

const MySpecialitiesSection = () => {
  return (
    <MySpecialitiesSectionLayout>
      <MySpectialitiesTitle>ההתמחויות שלי</MySpectialitiesTitle>
      <MySpecialitiesCardsContainer>
        {specilities.map((specility) => (
          <SpecialityCard
            key={`speciality${specility.name}`}
            speciality={specility}
          />
        ))}
      </MySpecialitiesCardsContainer>
    </MySpecialitiesSectionLayout>
  );
};

export default MySpecialitiesSection;
