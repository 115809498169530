import TherapyImg from "../../assets/img/therapy.jpg";
import QualificationImg from "../../assets/img/qualification.jpg";
import GuidanceImg from "../../assets/img/guidance.jpg";

export const specilities = [
  {
    name: "therapy",
    title: "טיפול",
    image: TherapyImg,
    points: [
      "עוסקת בטיפול אינטגרטיבי המותאם אישית לצרכיו של האדם ומשלבת טכניקות מגישות טיפוליות פסיכודינמיות, קוגניטיביות התנהגותיות, ועבודה על קשרים בינאישיים מיטיבים",
      "טיפול בהפרעות חרדה, דכאון, ומשברים נפשיים מגוונים",
      "	טיפול סביב משברי חיים שונים כגון – אובדן, טראומה, גירושין וכו'",
    ],
  },
  {
    name: "qulification",
    title: "הכשרה",
    image: QualificationImg,
    points: [
      "מרצה ומרכזת קורסים בבית הספר לשיקום בקריה האקדמית אונו",
      "קורס כלים להנעת שינוי- הגישה המוטיבציונית והמודל להערכה ופיתוח מוכנות לשינוי",
      "קורס תאום שיקום/טיפול בראי האדם והקהילה",
      "מעבירה הרצאות וימי השתלמות בארגונים לפי דרישה",
    ],
  },
  {
    name: "guidance",
    title: "הדרכה",
    image: GuidanceImg,
    points: [
      "מוסמכת להדרכת מנהלים בתחום בריאות הנפש מטעם האגף לבריאות הנפש במשרד הבריאות",
      "עוסקת בהדרכת מנהלים מקצועיים וצוותים העוסקים בתחום השיקום של אנשים המתמודדים עם מגבלה בבריאות הנפש ועם מוגבלויות שונות מתחום הרווחה",
    ],
  },
];
