import styled, { css, keyframes } from "styled-components";

const InputFieldText = css`
  color: ${({ theme }) => theme.palette.tertiaryColorDark};
  font-size: 14px;
  font-family: ${({ theme }) => theme.fontFamily};
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px 70px;
  margin-top: 25px;
  background-color: rgba(256, 256, 256, 0.5);
  box-shadow: 9px 0 16px #5655550d;

  & > :not(:last-child) {
    margin-bottom: 12px;
  }
`;

export const InputField = styled.div`
  display: flex;
  justify-content: flex-end;

  & > :first-child {
    margin-left: 12px;
  }
`;

export const Input = styled.input`
  border: none;
  border-bottom: 2px solid ${(props) => props.theme.palette.tertiaryColor};
  background-color: transparent;
  ${InputFieldText}

  &:focus-visible {
    outline: none;
    border-bottom-color: ${(props) => props.theme.palette.tertiaryColorDark};
  }

  &::placeholder {
    ${InputFieldText}
  }
`;

export const TextArea = styled.textarea`
  border: 2px solid ${(props) => props.theme.palette.tertiaryColor};
  background-color: transparent;
  resize: none;
  width: 45vw;
  padding: 2px;
  ${InputFieldText}

  &:focus-visible {
    outline: none;
    border-color: ${(props) => props.theme.palette.tertiaryColorDark};
  }

  &::placeholder {
    ${InputFieldText}
  }

  @media only screen and (max-width: ${(props) => props.theme.breakpoints.s}) {
    width: 80vw;
  }
`;

export const SendButton = styled.input.attrs({ type: "submit" })`
  background-color: ${(props) => props.theme.palette.mainColor};
  padding: 5px 20px;
  border-radius: 15px;
  border: none;
  font-size: 18px;
  color: ${(props) => props.theme.palette.tertiaryColorDark};
  transition: transform 0.5s ease;
  cursor: pointer;

  &:focus-visible {
    outline: 1px solid ${(props) => props.theme.palette.tertiaryColorDark};
  }

  :hover {
    box-shadow: ${(props) => props.theme.shadow};
    transform: scale(1.05);
  }

  :active {
    box-shadow: ${(props) => props.theme.shadow};
    transform: scale(0.9);
  }
`;

const FadeUp = keyframes`
    0%{
        opacity: 0;
    }
    70%{
        opacity: 0.5;
    }
    100%{
        opacity: 1;
    }
`;

export const Toaster = styled.div`
  display: none;
  justify-content: center;
  width: 60vw;
  padding: 10px;
  background-color: ${(props) => props.theme.palette.mainColorDark}33;
  color: ${(props) => props.theme.palette.tertiaryColorDark};
  border-radius: 1px;
  opacity: 0;

  &.animate {
    display: flex;
    animation: ${FadeUp} 1s ease-in forwards;
  }
`;
