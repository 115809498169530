import styled from "styled-components";
import { Paragraph } from "../generals/Typography.styles";

export const AboutSectionLayout = styled.div`
  /* display: flex;
  flex-direction: row;
  align-items: center; */
  margin: 0 auto;
  padding: 30px;
  padding-right: 55px;
  background-color: rgb(243 248 231 / 67%);
  width: 70%;
  transform: skewX(-20deg);
  overflow: hidden !important;

  @media only screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    transform: skewX(0);
  }

  @media only screen and (max-width: ${(props) => props.theme.breakpoints.s}) {
    background-color: rgb(243 248 231 / 25%);
    padding: 10px;
  }
`;

export const AboutPhotoContainer = styled.div`
  width: 130px;
  height: 130px;
  float: right;
  --webkit-shape-outside: circle(50% at 50% 50%);
  shape-outside: circle(50% at 50% 50%) padding-box;
  --webkit-clip-path: circle(50% at 50% 50%);
  clip-path: circle(50% at 50% 50%);
  transform: skewX(20deg);
  overflow: hidden !important;
  background-color: black;
  top: 0;
  position: absolute;
  position: relative;

  @media only screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    transform: skewX(0);
  }

  @media only screen and (max-width: ${(props) => props.theme.breakpoints.s}) {
    width: 100px;
    height: 100px;
  }
`;

export const AboutPhoto = styled.img`
  height: 100%;
  width: 100%;
  backface-visibility: hidden;
`;

export const AboutTextContainer = styled(Paragraph)`
  transform: skewX(20deg);
  /* line-height: 30px; */
  font-weight: 500;
  font-size: 13px;
  letter-spacing: 2px;

  @media only screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    transform: skewX(0);
  }
`;
