import React from "react";
import { Layout, Text, Logo } from "./Footer.styles";
import LogoImg from "../../assets/img/logo.jpeg";

const Footer = () => (
  <Layout>
    <Logo src={LogoImg} alt="Logo" aria-label="Logo" />
    <Text> &copy; כל הזכויות שמורות לאיילת אונגר</Text>
  </Layout>
);

export default Footer;
