import styled from "styled-components";
import { Paragraph } from "../generals/Typography.styles";

export const Layout = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-left: 10px;
  align-items: flex-end;
`;

export const Text = styled(Paragraph)`
  font-size: 10px;
`;

export const Logo = styled.img`
  width: 8vw;
  margin-bottom: 10px;

  @media only screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    width: 12vw;
  }

  @media only screen and (max-width: ${(props) => props.theme.breakpoints.ms}) {
    width: 15vw;
  }

  @media only screen and (max-width: ${(props) => props.theme.breakpoints.s}) {
    width: 20vw;
  }
`;
