import React, { useState, useEffect, useRef } from "react";
import AppBar from "../appbar/AppBar";
import AboutSection from "../aboutSection/AboutSection";
import {
  AboutSectionContainer,
  ContactMeSectionContainer,
  FooterContainer,
  HomepageContainer,
  LocationInfoSectionContainer,
  MyAgendaSectionContainer,
  AppBarContainer,
  SpecialitiesSectionContainer,
} from "./Homepage.styles";
import MyAgendaSection from "../myAgendaSection/MyAgendaSection";
import MySpecialitiesSection from "../MySpecialitiesSection/MySpecialitiesSection";
import LocationNContact from "../LocationContact/LocationNContact";
import ContactMe from "../ContactMe/ContactMe";
import Footer from "../Footer/Footer";
import TopButton from "../generals/TopButton";

const Homepage = () => {
  const [showGoTop, setshowGoTop] = useState(false);
  const refScrollUp = useRef();

  useEffect(() => {
    window.addEventListener("scroll", handleVisibleButton);

    return () => window.removeEventListener("scroll", handleVisibleButton);
  }, []);

  const handleVisibleButton = () => {
    const position = window.pageYOffset;

    setshowGoTop(position > 50);
  };

  const handleScrollUp = () => {
    console.log("te ref:", refScrollUp);
    refScrollUp.current.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest",
    });
  };

  return (
    <>
      <AppBarContainer>
        <AppBar />
      </AppBarContainer>
      <HomepageContainer>
        <AboutSectionContainer ref={refScrollUp}>
          <AboutSection />
        </AboutSectionContainer>
        <MyAgendaSectionContainer>
          <MyAgendaSection />
        </MyAgendaSectionContainer>
        <SpecialitiesSectionContainer>
          <MySpecialitiesSection />
        </SpecialitiesSectionContainer>
        <LocationInfoSectionContainer>
          <LocationNContact />
        </LocationInfoSectionContainer>
        <ContactMeSectionContainer>
          <ContactMe />
        </ContactMeSectionContainer>
        <FooterContainer>
          <Footer />
        </FooterContainer>
      </HomepageContainer>
      {showGoTop && <TopButton onClick={handleScrollUp} />}
    </>
  );
};

export default Homepage;
