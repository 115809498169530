import React from "react";
import { SecondaryTitle } from "../generals/Typography.styles";
import {
  DetailsContainer,
  Layout,
  Phone,
  Email,
  Row,
  Text,
  Link,
} from "./ContactMe.styles";
import ContactMeForm from "./ContactMeForm";

const ContactMe = () => (
  <Layout>
    <SecondaryTitle>צרו קשר</SecondaryTitle>
    <DetailsContainer>
      <Link href="tel:054-7691615">
        <Row>
          <Phone />
          <Text>054-7691615</Text>
        </Row>
      </Link>
      <Link href="mailto:ayeletun@gmail.com">
        <Row>
          <Email />
          <Text>ayeletun@gmail.com</Text>
        </Row>
      </Link>
    </DetailsContainer>
    <ContactMeForm />
  </Layout>
);

export default ContactMe;
