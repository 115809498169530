import styled from "styled-components";

export const SecondaryTitle = styled.h2`
  color: ${(props) => props.theme.palette.tertiaryColorDark};
  font-weight: 400;
  letter-spacing: 4px;
  text-shadow: ${(props) => props.theme.shadow};
  align-self: center;
  font-size: 30px;
  transition: transform 0.5s ease-in;

  &:hover {
    transform: scale(1.1);
  }

  /* @media only screen and (max-width: ${(props) =>
    props.theme.breakpoints.ms}) {
    font-size: 19px;
  } */

  @media only screen and (max-width: ${(props) => props.theme.breakpoints.s}) {
    font-size: 20px;
  }
`;

export const Paragraph = styled.p`
  color: ${(props) => props.theme.palette.tertiaryColorDark};
  text-shadow: ${(props) => props.theme.shadow};
  font-weight: 400;
  letter-spacing: 4px;
  line-height: 30px;
  font-size: 16px;

  @media only screen and (max-width: ${(props) => props.theme.breakpoints.s}) {
    font-size: 13px;
  }
`;
